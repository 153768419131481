import React from "react";
import CheckboxHiddenInput from "./styled/CheckboxHiddenInput";
import CheckboxInputContainer from "./styled/CheckboxInputContainer";
import StyledCheckboxInput from "./styled/StyledCheckboxInput";
import CheckboxLabel from "./styled/CheckboxLabel";
import { TextTypeAndSize } from "../Text";
import { ColorType } from "../../styles";

export type CheckboxComponentProps = {
  checked: boolean;
  value: string;
  onChange: (value: string) => void;
  label: React.ReactNode;
  labelSize?: TextTypeAndSize;
  lableColor?: ColorType;
  testId?: string;
  name?: string;
};

const CheckboxInput: React.FC<CheckboxComponentProps> = ({
  value,
  testId,
  checked,
  onChange,
  label,
  labelSize = "P14",
  lableColor = "SLATE700",
  name,
}) => {
  return (
    <CheckboxInputContainer>
      <CheckboxHiddenInput
        readOnly
        checked={checked}
        name={name}
        type="checkbox"
      />
      <StyledCheckboxInput
        data-testid={testId}
        name={name}
        value={value}
        checked={checked}
        onClick={() => onChange(value)}
      />
      <CheckboxLabel
        type={labelSize}
        color={lableColor}
        data-testid={`${testId}-label`}
        checked={checked}
        name={name}
        onClick={() => onChange(value)}
      >
        {label}
      </CheckboxLabel>
    </CheckboxInputContainer>
  );
};

export default CheckboxInput;

import React, { FC } from "react";
import { ColorType } from "../../styles/theme";
import { CardContainer } from "./styled";

export interface CardProps {
  elevation?: number;
  background?: ColorType;
  borderRadius?: number;
  justifyContent?: string;
  padding?: string;
  width?: string;
  testId?: string;
}

export const Card: FC<CardProps> = ({
  elevation = 0,
  background = "WHITE",
  borderRadius = 8,
  padding = "25px",
  width,
  testId,
  children,
}) => (
  <CardContainer
    elevation={elevation}
    background={background}
    borderRadius={borderRadius}
    data-testid={testId}
    padding={padding}
    width={width}
  >
    {children}
  </CardContainer>
);

export default Card;
